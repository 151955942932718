:root {
  --primary-80: #804012;
  --primary-70: #ab5618;
  --primary-60: #cb5500;
  --primary-default: #ff6b00;
  --primary-55: #cd8700;
  --primary-50: #ffb986;
  --primary-40: #ca8b77;
  --primary-30: #ada29a;
  --primary-20: #bda99b;
  --primary-10: #fffaf7;

  --gery-darkest: #1b1b1b;
  --gery-default: #515151;
  --gery-dark: #7f7f7f;
  --gery-light: #e3e3e3;
  --gery-lighter: #f6f4f3;
  --gery-white: #ffffff;
  --grey-50: #999999;

  --blue-dark: #203048;
  --blue-default: #2d4e80;
  --blue-light: #006995;

  --green-default: #00924c;

}
* {
  box-sizing: border-box !important;
}
html{
  font-size: 20px !important;
}

.container {
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  overflow-y: overlay;
}

.textOverflow {
  white-space: nowrap !important; /* 防止文本換行 */
  overflow: hidden !important; /* 隱藏超出的部分 */
  text-overflow: ellipsis !important; /* 添加省略號 */
}

input,
textarea {
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border: none;
  outline: none;
  padding: 16px 16px;
  resize: none;
}

input::placeholder,
textarea::placeholder{
  font-size: 15px;
  font-weight: 500;
  color: #939393;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  color: #939393 !important;
}
// ::-webkit-scrollbar-track {
// }
::-webkit-scrollbar-thumb {
  /* display: none !important; */
  background: var(--grey-50) !important;
  border-radius: 25px !important;
}
/* ::-webkit-scrollbar-thumb:hover {
    display: none !important;
  }  */
