body {
  margin: 0;
  /* 先指定通用字體 */
  /* font-family: Arial, sans-serif; */
  font-family: 'Inter', 'Microsoft JhengHei', Arial, sans-serif;

  /* 針對 Apple 系統 */
  @supports (font: -apple-system-body) {
    body {
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  /* 針對 Windows 系統 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    body {
      font-family: 'Inter', 'Microsoft JhengHei', Arial, sans-serif, 'Segoe UI';
    }
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
